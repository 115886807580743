:root {
  --primary-color: #c11b64;
  --secondary-color: #ff005c;
  --text-color-primary: #333b6a;
  --backdrop-bg: rgba(52, 60, 106, .175)
}

body {
  background-color: #fbfcfe !important;
  color: #333b6a;
  font-size: 16px !important;
}

iframe {
  max-width: 260px;
  height: 60px;
}

.page__bloc {
  margin-left: auto;
  min-height: 100vh;
}

.main-text-color {
  color: #c11b64 !important;
}

a,
a:hover {
  color: #c11b64;
}

.btn__gradient {
  background-color: #f32445;
  border-color: transparent;
  background-image: linear-gradient(45deg, #f32445, #a52874);
}

.btn__gradient:hover {
  opacity: .9;
}

.btn__gradient:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 27, 100, .5) !important;
}

.dropdown-item__bg_gradient:active {
  background-color: #f32445;
  background-image: linear-gradient(45deg, #f32445, #a52874);
}

.page__spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--backdrop-bg);
  z-index: 1;
}

.page__spinner.full-page {
  position: fixed;
}

.progress-bar__container {
  position: absolute;
  top: 0;
  width: 100%;
}

.custom__progress-bar {
  min-width: 6.6rem;
}

.custom__progress-bar .progress-bar {
  background-color: #c11b64;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100% !important;
}

.w-200px {
  width: 200px;
}

.h-25px {
  height: 25px;
}

.note__block {
  border-left: .25rem solid #c11b64;
}

.border_color_light-blue,
.table thead th,
.table td,
.table th {
  border-color: #e8e5eb !important;
}

.table {
  color: #343c6a !important;
}

.table thead th {
  border-width: 0 !important;
}

.table-hover tbody tr:hover {
  color: #343c6a;
  background-color: rgba(52, 60, 106, .05);
}

.border_radius_0 {
  border-radius: 0;
}

.border_radius_3 {
  border-radius: .75rem;
}

.border_radius-top-corner_3 {
  border-top-left-radius: .75rem !important;
  border-top-right-radius: .75rem !important;
}

.border_radius-bottom-corner_3 {
  border-bottom-left-radius: .75rem !important;
  border-bottom-right-radius: .75rem !important;
}

.border_radius_left-side_lg {
  border-radius: .3rem 0 0 .3rem;
}

.border_radius_right-side_lg {
  border-radius: 0 .3rem .3rem 0;
}

.custom__select.border_radius_left-side_lg>div {
  border-radius: .3rem 0 0 .3rem;
}

.custom__select.border_radius_right-side_lg>div {
  border-radius: 0 .3rem .3rem 0;
}

.custom__select.border_radius_0>div {
  border-radius: 0;
}

.custom__select>div:nth-child(2) {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(193, 27, 100, .25);
}

.list-group-item:first-child {
  border-radius: .75rem .75rem 0 0;
}

.list-group-item:last-child,
.table-list__header {
  border-radius: 0 0 .75rem .75rem;
}

.btn-outline-danger {
  border-color: #f04080 !important;
}

.btn-outline-danger:hover {
  background-color: #f04080 !important;
}

.btn.outline-link:hover {
  background-color: rgba(0, 0, 0, .15) !important;
}

.btn.btn-primary {
  background-color: #3366ff;
  border-color: #3366ff;
}

.btn.btn-outline-primary {
  border-color: #3366ff;
}

.btn.btn-outline-primary:hover {
  background-color: #3366ff;
  border-color: #3366ff;
}

.form-group.mb-2 {
  margin-bottom: .5rem !important;
}

.form-group.mb-0 {
  margin-bottom: 0 !important;
}

.form-control {
  color: #333b6a !important;
}

.text-muted {
  color: #7990b4 !important;
}

.color-primary {
  color: var(--text-color-primary);
}

.form-group {
  position: relative;
  margin-bottom: 1.5rem !important;
}

.form-label {
  position: absolute;
  top: -1.25rem;
  left: .5rem;
  max-width: calc(100% - 1rem) !important;
  background-color: white;
  margin-bottom: 0 !important;
  padding: 0 .5rem;
  border-radius: .25rem;
  height: auto !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.form-control {
  padding: .75rem 1rem;
}

.custom__select>div {
  padding: 0.3rem;
}

.custom__select-block .form-label {
  top: -1rem;
}

.react-select__custom-select+.form-label {
  left: calc(.5rem + 5px) !important;
}

.z_index_0 {
  z-index: 0;
}

.z_index_1 {
  z-index: 1;
}

.z_index_2 {
  z-index: 2;
}

.z_index_3 {
  z-index: 3;
}

.z_index_4 {
  z-index: 4;
}

.z_index_5 {
  z-index: 5;
}

.z_index_max {
  z-index: 1051;
}

.flex_1 {
  flex: 1;
}

.flex_2 {
  flex: 2;
}

/* sidebar */
.logo__block {
  background-color: transparent;
}

.image__thumbnail {
  background-color: inherit;
}

.alert-info {
  background-color: #e7edff;
  color: #396aff;
}

.custom__badge {
  padding: .75rem !important;
  border-radius: .75rem;
}

.custom__badge.badge-success,
.alert.alert-success {
  background-color: #e8fbfa;
  color: #16dbcc;
}

.custom__badge.badge-warning {
  background-color: #fff5d9;
  color: #ffbb38;
}

.custom__badge.badge-danger,
.alert.alert-danger {
  background-color: #fce9f3;
  color: #98164c;
}

.custom__toast.alert-info {
  border-color: #e7edff;
}

.custom__toast::before {
  content: '';
  position: absolute;
  border: 10px solid transparent;
  border-bottom-color: #ffffff;
  top: -20px;
  left: 10px;
}

.custom__toast.alert-info::before {
  border-bottom-color: #e7edff;
}

.shadow-xsm {
  box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .15) !important;
}

.primary-color {
  color: #98164c;
}

.with-checkmark {
  position: relative;
}

.with-checkmark::before,
.with-checkmark::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1.25rem transparent;
  border-radius: .5rem 0;
  text-align: left;
  line-height: 0;
  transition: all .5s;
  border-top-color: #98164c;
  border-left-color: #98164c;
  opacity: 0;
}

.with-checkmark::after {
  font-size: 1.25rem;
  border: #ffffff solid 2px;
  border-top-width: 0;
  border-right-width: 0;
  transform: rotate(-45deg);
  width: 1.25rem;
  height: 0.5rem;
  top: 0.25rem;
  left: 0.25rem;
  border-radius: 0;
}

.custom__radio:checked+.with-checkmark::before,
.custom__radio:checked+.with-checkmark::after {
  opacity: 1;
}

.custom__radio-after {
  border: solid transparent 3px;
  transition: all .5s;
}

.custom__radio:checked+.custom__radio-after {
  border-color: #98164c;
  box-shadow: none !important;
}

.custom__radio.with-disable-effect:disabled+.custom__radio-after {
  filter: blur(8px);
}

.custom__card-checkbox {
  height: 160px;
  width: 160px;
}

.bg-primary {
  background-color: #98164c !important;
}

.bg-primary_light {
  background-color: #c11b64 !important;
}

.bg-grey {
  background-color: #fbfcfe;
}

.shadow-text {
  font-size: 2rem;
  text-shadow: 2px 2px 2px #98164c, -2px -2px 2px #98164c, -2px 2px 2px #98164c, 2px -2px 2px #98164c;
  color: white;
  letter-spacing: 2px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px;
}

.modal-90w {
  max-width: 1200px;
  width: 90%;
}

.pay-action__block {
  left: auto;
  right: 0;
  width: 75%;
}

.position-absolute_bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.w-70px {
  width: 70px;
}

/* Sign in/up video background css */
.bg-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.padding_hotizontal_0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding_vertical_0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.bg-dark-1 {
  background-color: rgba(0, 0, 0, .35);
}

.fl-text-capitalize {
  text-transform: lowercase !important;
}

.fl-text-capitalize::first-letter {
  text-transform: uppercase !important;
}

.release-image img {
  max-height: 300px !important;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: rgba(193, 27, 100, .25);
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(193, 27, 100, .25);
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem rgba(193, 27, 100, .25);
}

.form-control::placeholder {
  opacity: .5;
}

/* FLAGS CSS */
.flag__container {
  height: 40px;
  width: 40px;
}

.flag__container picture {
  height: 100%;
  display: block;
  position: relative;
}

.flag__container picture img {
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* CHART CSS */
.chart__block,
.chart__block>div {
  height: 300px;
  max-height: 300px;
}

@media only screen and (max-width: 700px) {
  .release-image {
    max-height: 200px;
  }

  .pay-action__block {
    width: 100%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  }
}

@media only screen and (max-width: 576px) {
  .modal-90w {
    width: auto !important;
  }
}

@media only screen and (max-width: 370px) {
  .custom__card-checkbox {
    height: 135px;
    width: 135px;
  }
}